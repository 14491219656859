import styled from 'styled-components'
import theme from 'common/style/styleSetup'
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // box-shadow: inset 0px 0px 10px 10px hsl(178deg 100% 13%);
  // background: linear-gradient(to top, #090d0e, #134e5e);
  // box-shadow: inset 0px 0px 10px 10px ${theme.bg[22]};
  // background: linear-gradient(to top, ${theme.bg[23]}, #bf0000);
  background: rgb(13 10 39);
  border: none;
  margin-bottom: 10px;
`
export const Avatar = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  p {
    margin: 0px;
  }
`
export const Row = styled.div`
  display: flex;
`
export const Info = styled.div`
  color: yellow;
  margin-left: 30px;
  cursor: pointer;
  img {
    width: 20px;
  }
  span {
    color: white;
  }
`
export const Content = styled.div`
  display: flex;
  height: 30px;
  align-items: center;
  filter: brightness(0.85);
  p {
    margin: 0;
    font-weight: 300;
  }
  padding: 5px;
  margin: 2px;
  span {
    color: white !important;
  }
  :hover {
    filter: brightness(1.5);
  }

  ::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 80%;
    background-color: olive;
    transform: skewX(-5deg);
    transform-origin: right center;
    opacity: 0;
    left: -1em;
    transition: opacity 0.3s ease-in-out;
    box-shadow: 0 0 0.4em olive;
  }
  :hover::before,
  :hover::after {
    opacity: 0.9; /* Set opacity to 0 to disable the shadow effect */
  }
  cursor: pointer;
  :hover p {
    font-size: 18px;
  }
`
export const AvatarImg = styled.div`
  text-align: center;
  background: white;
  width: 45px;
  height: 45px;
  border-radius: 50px;
  justify-content: center;
  display: flex;
  margin: 0px;
  img {
    width: 35px;
    height: 35px;
    margin-top: 3px;
    cursor: pointer;
  }
`
export const Wrapper = styled.div`
  width: 100%;
  border-top: solid 1px 1px ${theme.bg[9]};
  border-bottom: solid 1px 1px ${theme.bg[9]};
  background-image: linear-gradient(#23344a, #13253e);
  box-shadow: inset 0 3px 0 #0e151e;
`

export const WrapperTop = styled.div`
  max-width: 1350px;
  width: 100%;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 auto;
  gap: 5px;
  z-index: 10;
  @media (max-width: 1024px) {
    justify-content: space-between;
    height: 50px;
  }
`

export const TopLogo = styled.div`
  cursor: pointer;
  /* min-width: 300px; */
  display: flex;
  justify-content: center;

  img {
    height: 43px;
    animation-name: logoAnimation;
    animation-duration: var(--animate-duration, 1s);
    animation-delay: var(--animate-delay, 1s);
    animation-iteration-count: var(--animate-repeat, infinite);
    @media (max-width: 1024px) {
      height: 38px;
    }
  }

  @keyframes logoAnimation {
    0% {
      transform: scaleX(1);
    }
    30% {
      transform: scale3d(1.25, 0.75, 1) scaleY(1.5);
    }
    40% {
      transform: scale3d(0.75, 1.25, 1) scaleY(1.25);
    }
    50% {
      transform: scale3d(1.15, 0.85, 1) scaleY(1.4);
    }
    65% {
      transform: scale3d(0.95, 1.05, 1) scaleY(0.95);
    }
    75% {
      transform: scale3d(1.05, 0.95, 1) scaleY(1.25);
    }
    100% {
      transform: scaleX(1);
    }
  }
`

export const TopIcon = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: block;
    height: 50px;
    padding: 4px 10px;
  }
  .icon {
    width: 47px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #0078ae;
    box-shadow:
      rgba(0, 0, 0, 0.35) -3px 7px 6px -6px,
      rgb(92, 94, 108) 1px 1px 4px -2px inset;
    border-radius: 5px;
    :hover {
      background: #393a43;
    }
  }
`

export const MenuContent = styled.div`
  width: calc(100% + 280px);
  height: 100vh;
  /* position: absolute; */
  background-color: #00000080;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  display: flex;
  justify-content: flex-start;
  transition: all 0.4s;
  left: -280px;
  .icon-close {
    width: 20px;
    position: absolute;
    top: 10px;
    left: 260px;
    cursor: pointer;
    z-index: 1;
  }
  &.active {
    left: 0px;
    transition: all 0.4s;
    z-index: 99;
  }
`
export const UserContent = styled.div`
  width: calc(100% + 280px);
  height: 100vh;
  /* position: absolute; */
  background-color: #00000080;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  display: flex;
  justify-content: flex-end;
  transition: all 0.4s;
  .icon-close {
    width: 20px;
    position: absolute;
    margin-left: -8px;
    top: 6px;
    z-index: 10;
  }
  &.active {
    left: -280px;
    transition: all 0.4s;
    z-index: 99;
  }
`
export const MenuContentItem = styled.div`
  width: 280px;
  height: 100%;
  overflow-x: hidden;
  background: #202024;
  padding: 15px 10px;
  margin-right: 0;
  .ant-menu {
    background: #202024;
    width: 270px !important;
    margin-left: -5px;
    .ant-menu-item {
      border: 1px solid #202024;
      border-radius: 5px;
      padding-left: 20px !important;
      height: 40px;
      background: #2d2e35;
      color: #c8c8c7;
      :hover {
        color: #ffffff !important;
        background: #393a43 !important;
      }
    }
    .ant-menu-title-content {
      font-family: 'NotoSansKr-Bold';
    }
    .ant-menu-submenu {
      padding-left: 0 !important;
      color: #c8c8c7;
      .ant-menu-submenu-title {
        border: 1px solid #202024;
        border-radius: 5px;
        height: 40px;
        background: #2d2e35;
        span {
          font-family: 'NotoSansKr-Bold';
        }
      }
      :hover {
        .ant-menu-submenu-title {
          color: #ffffff !important;
          background: #393a43 !important;
        }
      }
      .ant-menu-submenu-arrow {
        display: none;
      }
      .ant-menu-sub {
        margin: -4px 0;
        .ant-menu-item {
          border: 1px solid #1c4b5a;
          color: #858c83;
          background: #161821;
          span {
            font-family: 'NotoSansKr-Bold';
          }
        }
      }
    }

    &.menu-222 {
      margin-top: -4px;
      .ant-menu-item {
        background: #213b44;
        color: #6abbd6;
        :hover {
          background: #213b44 !important;
          color: #6abbd6 !important;
        }
      }
      .ant-menu-submenu {
        color: #6abbd6;
        .ant-menu-submenu-arrow {
          display: none;
        }
        .ant-menu-submenu-title {
          border: 1px solid #202024;
          border-radius: 5px;
          height: 40px;
          background: #213b44;
          span {
            font-family: 'NotoSansKr-Bold';
          }
        }
        :hover {
          .ant-menu-submenu-title {
            color: #6abbd6 !important;
            background: #213b44 !important;
          }
        }
      }
    }
  }
`

export const ItemButton = styled.div`
  display: flex;
  color: white;
  align-items: center;
  margin: 0;
  font-size: 15px;
  font-weight: 700;
  padding: 0px 5px;
  cursor: pointer;
  img {
    margin-right: -3px;
  }
`
export const LoginButton = styled.div`
  p {
    margin: 0px;
    color: white;
  }
  padding: 10px 20px;
  background: gray;
`
export const SignUpButton = styled.div`
  p {
    margin: 0px;
    color: white;
  }
  padding: 10px 20px;
  background: #21ba72;
`
export const TopButton = styled.div`
  display: flex;
  /* min-width: 1310px; */
  overflow: hidden;
  align-items: center;
  @media (max-width: 1024px) {
    display: none;
  }
  .content-top {
    height: 25px;
    font-weight: 600;
    color: ${theme.color[0]};
    justify-content: center;
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 1px 8px 0;
    border-radius: 4px;
    font-size: 12px;
    transition: all 0.3s ease-in-out;
    &.logout {
      background: ${theme.bg[11]};
      border: solid 1px ${theme.bg[14]};
      border: none;
    }
    :hover {
      // background: ${theme.bg[13]};
      transform: scale(1.2);
      cursor: pointer;
      &.logout {
        background: ${theme.bg[12]};
      }
    }
    .fa {
      margin: -3px 4px 0 0;
    }
  }
`

export const WrapperBot = styled.div`
  max-width: 1350px;
  width: 100%;
  height: 46px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin: 0 auto;
  .item {
    color: ${theme.color[7]};
    width: 10%;
    height: 24px;
    padding: 0 12px;
    text-align: center;
    border-left: 1px solid ${theme.bg[15]};
    justify-content: center;
    display: flex;
    align-items: center;
    :nth-child(10) {
      border-right: 1px solid ${theme.bg[15]};
    }
    .content {
      width: 100%;
      height: 28px;
      padding-top: 1px;
      line-height: 28px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      :hover {
        background: ${theme.bg[16]};
        border: solid 1px ${theme.bg[17]};
      }
    }
  }
`
