import { mainAxios } from 'libs/axios'

export const popupAPI = () => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/popup`,
    // payload: payload,
    // requiresToken: false,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}
