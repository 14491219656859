import types from '../actions/types'

const defaultState = {
  isBetting: false
}

export default (state = defaultState, action: any) => {
  const type = action?.type

  switch (type) {
    case types.IS_BETTING:
      return { isBetting: action.payload }
    default:
      return state
  }
}