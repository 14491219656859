/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
// /* eslint-disable prettier/prettier */
import React, { useEffect, useMemo, useState } from 'react' //lazy

import { Layout } from 'antd'
import { UrlInternal } from 'common/constants/endpoints'
// import { ArrowUpOutlined } from "@ant-design/icons";
import { sportCategoryApi, getSports } from 'apis'
import iconSport from 'assets/icons/ico_left_02.png'
import Item from './item'
import ItemMatch from './itemMatch'
import * as S from './style'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import types from 'store/actions/types'
import LoginModal from 'pages/ModalLogin'

interface Props {
  onClick?: any
  className?: any
}

const ListSport = (props: Props) => {
  const { onClick, className } = props
  const userData: any = localStorage.getItem('User')
  const dispatch = useDispatch()
  const history = useHistory()
  const path = window.location.pathname
  const { sportsMatchList } = useSelector((state:any)=> state?.sportsMatchList)
  const [open, setOpen] = useState<any>(false)
  const checkPathIsMinigame = useMemo(() => {
    const pathStr = '/' + path.split('/')[1]

    if (pathStr === UrlInternal.MINI_GAME) return true

    return false
  }, [path])
  const getSportsMatch = async ()=>{
    try {
      const params = {
        type: '0',
        gamegubun: ''
      }
      const res: any = await getSports(params)
      if (res.result === false) {
        alert(res.message)
      } else {
        dispatch({
          type: types.SPORTS_MATCH_LIST,
          sportsMatchList: res.data
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getMiniSport = async () => {
    try {
      const params = {
        type: '0'
      }
      const res: any = await sportCategoryApi(params)
       console.log(res);
       if(res.error.message === 'SITE_CLOSE'){
        history.push(`/site-maintain`)
       }
      if (res.result === false) {
        alert(res.message)
      } else {
        dispatch({
          type: types.SPORTS_GAME_LIST,
          sportsGameList: res
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleClick = (e: any, category: string, type: string) => {
    if(userData){
      const newUrl = new URLSearchParams(window.location.search)
      newUrl.set('category', category)
      if (path.includes(UrlInternal.SPORT)) {
        history.push(`${window.location.pathname}?${newUrl.toString()}`)
      } else {
        history.push(
          `${UrlInternal.SPORTS_DETAIL_ROUTER}${type}?${newUrl.toString()}`
        )
      }
    }
    else{
        dispatch({type: 'OPEN_LOGIN'})
    }
  }

  useEffect(() => {
    getMiniSport()
    getSportsMatch()
  }, [])
  useEffect(()=>{

  }, [open])

  return (
    <S.Wrapper>
      <S.Label>
        <img src={iconSport} alt=''/>   <a>최신</a> 인기게임
      </S.Label>
      <S.Content>
      {sportsMatchList.filter((v:any)=> v.type === 'game').slice(0,5).map((v: any, i: number) => {
          return(
          <div 
            // onClick={(e) =>  handleClick(e, v?.category, v?.type)}
            key={i}>
            <ItemMatch data={v.items[0]} />
          </div>
        )})}

      </S.Content>
      <LoginModal/>
    </S.Wrapper>
  )
}

export default ListSport
