import React, { useEffect, useMemo, useState } from 'react'
import * as S from './style'
import { Button3, Button4, Button5, Button6 } from 'components'
import moment from 'moment-timezone'
import { useDispatch, useSelector } from 'react-redux'
import moneyConvert, { moneyConvertToNumber } from 'utils/moneyConvert'
import types from 'store/actions/types'
import { useParams } from 'react-router-dom'
import { bettingSports, getSports, meApi } from 'apis'
import { convertNewLine } from 'utils/convertNewLine'
import cartIcon from 'assets/images/header-icons/ico_cart_01.png'
type Props = {}
interface ParamsGetSports {
  type: string;
  gamegubun?: string | null;
  category?: string | null;
}
const dataButtonMoney = [
  { value: 5000, text: '+ 5,000' },
  { value: 10000, text: '+ 10,000' },
  { value: 50000, text: '+ 50,000' },
  { value: 100000, text: '+ 100,000' },
  { value: 500000, text: '+ 500,000' },
  { value: 1000000, text: '+ 1,000,000' }
]
function BetSlipSportsGame({ }: Props): React.ReactElement {
  const dataMe = useSelector((state: any) => state.user)
  const { dataBetting } = useSelector((state: any) => state.bettingSports)
  const [eventItem, setEventItem] = useState<any>({})
  const [dataEvent, setDataEvent] = useState<any>([])
  const dispatch = useDispatch()
  const { id: type }: { id: string } = useParams();
  const { isBetting } = useSelector((state: any) => state.isBetting)
  const filterData = dataBetting.filter((item: any) => !["e1", "e2", "e3"].includes(item.idx))
  const [smallestEvent, setSmallestEvent] = useState<any>({})
  const [mediumEvent, setMediumEvent] = useState<any>({})
  const [biggestEvent, setBiggestEvent] = useState<any>({})

  const [moneyBet, setMoneyBet] = useState(0)


  const handleChangeBetMoney = (e: any) => {
    const { value } = e.target
    if (!isNaN(moneyConvertToNumber(value))) {
      const isPassMoney =
        dataMe.dataUser?.mb_money - moneyConvertToNumber(value) < 0
      if (isPassMoney) {
        return dispatch({
          type: types.POPUP_ACTION_OPEN,
          payload: {
            msg: `배팅할 보유머니가 부족합니다..`,
            typePopup: 'CONFIRM'
          }
        })
      }
      setMoneyBet(moneyConvertToNumber(value))
    }
  }

  const handleReset = () => {
    setMoneyBet(0)
  }

  const handleClickButtonMoney = (e: any, money: number) => {
    const isPassMoney = dataMe.dataUser?.mb_money - (moneyBet + money) < 0
    if (isPassMoney) {
      return dispatch({
        type: types.POPUP_ACTION_OPEN,
        payload: {
          msg: `배팅할 보유머니가 부족합니다..`,
          typePopup: 'CONFIRM'
        }
      })
    }

    setMoneyBet((prev) => prev + money)
  }

  const handleMaxMoneyBet = () => {
    setMoneyBet(dataMe.dataUser?.mb_money)
  }

  const handleDeleteOneBetting = (e: any, idx: string | number) => {
    dispatch({
      payload: idx,
      type: types.REMOVE_DATA_BETTING_SPORTS
    })
  }

  const handleResetAll = () => {
    dispatch({
      type: types.RESET_DATA_BETTING_SPORTS
    })
    setMoneyBet(0)
  }

  const onBetting = async () => {
    const mergeEachFieldToArr = dataBetting.reduce((acc: any, obj: any) => {
      for (const key in obj) {
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(obj[key])
      }
      return acc
    }, {})
    const { div_sel, game_type, idx, isCanBet, pick, ratio } =
      mergeEachFieldToArr

    const payload = {
      type: type,
      room: '',
      bettingcnt: dataBetting.length,
      bettingdiv: parseFloat(sumBettingDiv),
      bettingwin: moneyWin,
      idx_list: idx?.join('|'),
      pick_list: pick?.join('|'),
      betDiv_list: ratio?.join('|'),
      gameType_list: game_type?.join('|'),
      gmoney: dataMe.dataUser?.mb_money,
      bet_org_list: div_sel?.join('|'),
      betMoney: moneyConvert(moneyBet, true),
      idx: idx?.map((element: number) => String(element)),
      pick: pick?.map((element: string) => Number(element)),
      betDiv: ratio?.map((element: string) => Number(element)),
      gameType: game_type?.map((element: string) => Number(element)),
      bet_div_list: div_sel
    }
    if (isCanBet?.every((element: boolean) => element === true) && isBetting === true) {
      try {
        const res: any = await bettingSports(payload)

        if (res?.result) {
          handleResetAll()
          alert(res?.message)
        } else if (!res?.error?.result) {
          alert(convertNewLine(res?.error?.message))
        }
        dispatch({ type: 'STOP_WAITTING' })
      } catch (error) {
        console.log(error)
        dispatch({ type: 'STOP_WAITTING' })
      }
    }
  }

  const sumBettingDiv = useMemo(() => {
    const sum: number = dataBetting.reduce(
      (accumulator: number, currentValue: any) => {
        return parseFloat(currentValue.ratio) * accumulator
      },
      1
    )

    return sum.toFixed(2)
  }, [dataBetting])

  const moneyWin = useMemo(() => {
    const mWin = parseFloat(sumBettingDiv) * moneyBet

    return mWin.toFixed()
  }, [sumBettingDiv, moneyBet])

  const getEvent = () => {
    dispatch({
      type: types.IS_BETTING,
      payload: true
    })
    const nameElement = () => (
      <></>
    );
    const payloadIdx = {
      idx: eventItem?.idx,
      pick: "0",
      ratio: eventItem?.event_odds,
      game_type: "6",
      div_sel: "undefined",
      nameElement: nameElement(),
      isCanBet: true,
      type: "event",
    };
    if (Object.keys(eventItem).length > 0) {
      dispatch({
        payload: payloadIdx,
        type: types.ADD_DATA_BETTING_SPORTS,
      });
    }
  }
  const handleBetting = () => {
    // getEvent()
    dispatch({
      type: types.POPUP_ACTION_OPEN,
      payload: {
        msg: `배팅을 하시겠습니까?`,
        typePopup: 'ACTION',
        actionText: 'OK',
        action: getEvent
      }
    });
  }
  const params: any = {
    type: 0,
    gamegubun: '',
    category: "",
  };

  useEffect(() => {
    const fetchSports = async (params: ParamsGetSports) => {
      try {
        const res: any = await getSports(params);

        if (res.data !== undefined) {
          const tempData = [...res.data];
          const newDataEvent = tempData.find((item) => item.type === "event");
          setDataEvent(newDataEvent.items);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchSports(params)
  }, [])
  const sortEvent:any = dataEvent.sort((a:any, b:any) => a.event_cnt - b.event_cnt);
  useEffect(()=>{
    setSmallestEvent(sortEvent[0])
    setMediumEvent(sortEvent[Math.floor(sortEvent.length / 2)])
    setBiggestEvent(sortEvent[sortEvent.length - 1])
  },[dataEvent])
  useEffect(() => {
    if (filterData.length >= sortEvent[0]?.event_cnt && filterData.length <sortEvent[Math.floor(sortEvent.length / 2)]?.event_cnt) {
      setEventItem(smallestEvent)
    }
    else if ( filterData.length >= sortEvent[Math.floor(sortEvent.length / 2)]?.event_cnt && filterData.length < sortEvent[sortEvent.length - 1]?.event_cnt) {
      setEventItem(mediumEvent)
    }
    else if (filterData.length >= sortEvent[sortEvent.length - 1]?.event_cnt) {
      setEventItem(biggestEvent)
    }
    else if (filterData.length < sortEvent[0]?.event_cnt) {
      setEventItem({})
    }
  }, [dataBetting, eventItem?.id, isBetting, dataEvent, sortEvent])
  useEffect(() => {
    if (isBetting) {
      onBetting()
    }
  }, [isBetting])
 
  return (
    <S.Wrapper>
      <S.Header>
        <div className='header'>
          <img src={cartIcon} alt='cart-icon' />
          <p>BETTING CART</p>
        </div>
        <div>
          {/* <span className='clock'>{time}</span> */}
          <div className="alert alert-success pbox1">

            {dataEvent?.length > 0 &&
              dataEvent?.map((value: any, i: any) => {
                return (
                  <>
                    <span key={i}><strong>+ {value.event_name} </strong></span><br />
                  </>
                )
              }
              )}
          </div>
        </div>
      </S.Header>
      <S.Betting>
        {dataBetting.length > 0 && (
          <S.ListBetting>
            {dataBetting.filter((item: any) => !["e1", "e2", "e3"].includes(item.idx)).map((item: any, index: number) => (
              <div key={`${item.idx}_${index}`} className='inner'>
                <div className='top'>{item?.nameElement}</div>
                <div className='bottom'>
                  <button
                    onClick={(e) => handleDeleteOneBetting(e, item?.idx)}
                    className='btn'
                  >
                    선택삭제
                  </button>
                  <span>{item.ratio}</span>
                </div>
              </div>
            ))}
            <S.Folder>
              {filterData.length >= biggestEvent?.event_cnt ? <p style={{ color: '#ea1858' }}>{biggestEvent?.event_name}:{biggestEvent?.event_odds}</p> : filterData.length >= mediumEvent?.event_cnt
                ? <p style={{ color: 'orange' }}>{mediumEvent?.event_name}:{mediumEvent?.event_odds}</p> : filterData.length >= smallestEvent?.event_cnt ?
                  <p style={{ color: '#2eb8ff' }}>{smallestEvent?.event_name}:{smallestEvent?.event_odds}</p> : <></>
              }
            </S.Folder>
          </S.ListBetting>
        )}
        <S.Table>
          <colgroup>
            <col width='30%' />
            <col width='*' />
          </colgroup>
          <S.Tbody>
            <tr>
              <th>보유금액</th>
              <td>
                <p className='num'>
                  {moneyConvert(dataMe.dataUser?.mb_money, true)}
                </p>
              </td>
            </tr>
            <tr>
              <th>전체배당</th>
              <td>
                <p className='bg_p red'>
                  X <span>{sumBettingDiv}</span>
                </p>
              </td>
            </tr>
            <tr>
              <th>적중금액</th>
              <td>
                <p className='bg_p'>{moneyConvert(moneyWin, true)}</p>
              </td>
            </tr>
            <tr>
              <th>배팅금액</th>
              <td>
                <input
                  type='string'
                  onChange={handleChangeBetMoney}
                  className='betmoney'
                  value={moneyConvert(moneyBet, true)}
                />
              </td>
            </tr>
          </S.Tbody>
        </S.Table>
        <S.ButtonWrapper>
          <S.ButtonMoneyWrappper>
            {dataButtonMoney.map((item, index) => (
              <Button4
                onClick={(e: any) => handleClickButtonMoney(e, item.value)}
                key={index}
              >
                {item.text}
              </Button4>
            ))}
          </S.ButtonMoneyWrappper>
          <S.ButtonResetWrapper>
            <Button5 onClick={handleReset}>초기화</Button5>
            <Button5 onClick={handleMaxMoneyBet}>최대</Button5>
          </S.ButtonResetWrapper>
          <S.ButtonSubmitWrapper>
            <Button6 className='button-bet' onClick={handleBetting}>배팅</Button6>
          </S.ButtonSubmitWrapper>
          <S.ButtonSubmitWrapper>
            <S.RightButton onClick={handleResetAll}>충전하기</S.RightButton>
          </S.ButtonSubmitWrapper>
        </S.ButtonWrapper>

      </S.Betting>
    </S.Wrapper>
  )
}

export default BetSlipSportsGame
