import styled from 'styled-components'

export const Wrapper = styled.button`
  width: 96%;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  border: 1px solid #305f28;
  background: #23221d;
  font-size: 12px;
  box-shadow: inset 0px 0px 10px 10px hsl(178deg 100% 50% / 15%);
  float: left;
  margin-left: 4px;
  line-height: 26px;
  font-weight: bold;
  margin-bottom: 4px;
  cursor: pointer;
  position: relative;
  transition: 200ms all;
  border-radius: 3px;

  &:hover {
    background: #00ecff;
  }
`
