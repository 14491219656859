import styled from 'styled-components'
import theme from 'common/style/styleSetup'
import common from 'assets/images/templates/common.png'
import { Button } from 'antd'

export const Wrapper = styled.div`
  width: 100%;
  background: #3a3a42;
  background-color: #3a3a42;
  padding: 4px;
  border-radius: 5px;
`

export const BoxInfo = styled.div`
  width: 100%;
  border-radius: 4px;
  .member-login {
    font-weight: bold;
    padding: 5px 0px 5px 5px;
    color: white;
    font-size: 12px;
  }
  .item {
    cursor: pointer;
    width: 100%;
    height: 28px;
    background: #1b2332;
    color: #39c0e3;
    /* border-radius: 2px;
    font-size: 12px;
    font-weight: 600; */
    font-size: 12px;
    font-weight: normal;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .fa {
      margin: 0 3px;
    }
    .icicon {
      background: url(${common}) no-repeat;
      -webkit-background-size: 304px 944px;
      background-size: 304px 944px;
    }
    .money .icicon {
      display: inline-block;
      width: 11px;
      height: 11px;
      margin: 0 3px;
      background-position: -54px -48px;
      vertical-align: top;
    }
    .point .icicon {
      display: inline-block;
      width: 11px;
      height: 11px;
      margin: 0 3px;
      background-position: -69px -48px;
      vertical-align: top;
    }
    &.label {
      color: #fcdc3c;
      padding: 4px 10px 0;
    }
    &.infos {
      border-top: 1px solid #303c56;
      font-size: 11px;
      font-weight: normal;
      padding: 0 6px;
      .value {
        color: #fff;
        margin: 0 4px;
      }
    }
  }
`
export const RightForm = styled.div`
  width: 100%;
  position: relative;
  .ant-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  .ant-form-item {
    margin: 0;
    width: 100%;
  }

  .captcha-box {
    position: absolute;
    top: 145px;
    left: 8px;
    z-index: 9;
    canvas {
      border-radius: 6px;
    }
    button {
      margin: 0;
      border: 3px;
      margin-left: -100px;
      width: 100px;
      height: 55px;
      cursor: pointer;
      opacity: 0;
    }
  }
`
export const RightFormItem = styled.div`
  width: 100%;
  // height: 57px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #e8f0fe;
  border: 1px solid #41b3ae;

  border-radius: 5px;
  input {
    width: 100%;
    height: 34px;
    color: black;
    background: #e8f0fe !important;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
    text-align: center;
    font-weight: bold;
    ::placeholder {
      color: gray;
      font-weight: bold;
    }
    :focus {
      outline: none;
    }
  }
`
export const RightButton = styled(Button)`
  width: 100%;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: 0.3s;
  // background: linear-gradient(to top, #00757d, #0078ae);
  background: rgb(31 24 66);
  box-shadow: 4px 7px 12px 0 rgb(119 80 248 / 20%);
  font-size: 13px;
  color: ${theme.color[0]};
  box-shadow: inset 2px 2px 2px 0px rgba(255,255,255,.5), 7px 7px 20px 0px rgba(0,0,0,.1), 4px 4px 5px 0px rgba(0,0,0,.1);
  transition: all 0.3s ease;
  background-size: 100% 200%; 
  background-position: 0% 100%;
  border: none;
  z-index: 1;

  :hover {
    color: black !important;
  }
  :hover::after {
    top: auto;
    bottom: 0;
    height: 100%;
  }
  ::after {
    color:black !important;
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 5px;
    background-color: #c9edea;
    background-image: linear-gradient(315deg,#c9edea 0%,#f6fc9c 74%);
    box-shadow: inset 2px 2px 2px 0px rgba(255,255,255,.5);
    7px 7px 20px 0px rgba(0,0,0,.1),4px 4px 5px 0px rgba(0,0,0,.1): ;
    transition: all 0.3s ease;
`
export const RightContent = styled.div`
  width: 484px;
  padding: 64px;
  border-radius: 12px;
  background-color: ${theme.bg[2]};
  box-shadow: 0 0 60px 0 rgb(0 0 0 / 12%);
`
export const ButtonBox = styled.div`
  width: 100%;
  height: auto;
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  span {
    // border: 1px solid #02ffe5;
    border: 1px solid #d4ff54;
    background: #000000;
    text-align: center;
    display: inline-block;
    line-height: 18px;
    vertical-align: top;
    width: 30px;
    height: 20px;
    border-radius: 2px;
    margin: 2px 0px 0px 4px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 3px;
  }
  .pri1 {
    width: 122px;
  }
  .bt {
    color: #fff;
    // border: 1px solid #41b3ae;

    background: #23221d;
    border-radius: 3px;
    font-size: 12px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 3px;
    float: left;
    line-height: 26px;
    font-weight: bold;
    margin-bottom: 4px;
    cursor: pointer;
    position: relative;
    transition: 200ms all;
    :hover {
      background: #00ecff;
      // background: #7f2520;
    }
  }
`
