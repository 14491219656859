import React, { useEffect } from 'react'
import { UrlInternal } from 'common/constants/endpoints'
import { useSelector } from 'react-redux'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { getLocalStorage } from 'utils/localStorage'

export function PublicRoute({
  component: TargetPage,
  ...rest
}: any): React.ReactElement {
  return <Route {...rest} render={(props: any) => <TargetPage {...props} />} />
}

export function PrivateRoute({
  component: TargetPage,
  isAuthenticated = true,
  ...rest
}: any): React.ReactElement {
  const history = useHistory()
  const userData = useSelector((state: any) => state.user)
  const user = userData.dataUser
  const token = localStorage.getItem('User')
  console.log(Object.keys(user).length, token)
  useEffect(() => {
    getLocalStorage({ typeGetData: 'User' }).then((v: any) => {
      if (!token && !v) {
        history.push({
          pathname: UrlInternal.LOGIN,
          state: { fromURL: history.location.pathname }
        })
      }
    })
  }, [])

  return (
    <Route
      {...rest}
      render={(props: any) =>
        userData || isAuthenticated ? (
          <TargetPage {...props} />
        ) : (
          <Redirect
            to={{
              pathname: UrlInternal.LOGIN,
              state: { fromURL: props?.location?.pathname }
            }}
          />
        )
      }
    />
  )
}
