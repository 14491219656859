import styled from 'styled-components'

// interface ItemProps {
//   bg?: any;
// }

export const Container = styled.div`
  max-width: 100vw;
  // left: calc(50% - 50px);
  margin: auto;
  position: absolute;
  top: 10%;
  // left: 5%;
  // transform: translate(-50%, -80%);
  // top: 20px;
  z-index: 99;
  font-size: 12px;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  &.false {
    display: none;
  }
  .popup {
    margin: 10px;
    padding: 10px;
    background: #000;
    height: max-content;
  }
  .img{
    img{
      max-width: 250px;
    }
  }
  .content {
    margin: -6px 1px 2px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap:5px;
    .content-text{
      position: relative;
    }
    .check-box{
      display: flex;
    }
    .box {
      position: absolute;
      width: 18px;
      height: 18px;
      background: #fff;
      border-radius: 2px;
      cursor: pointer;
      border: 1px solid #cbcbcc;
      font-size: 600;


    }
    .text {
      line-height: 20px;
    }
  }
  .button {
    // width: 100px;
    // width: 100px;
    padding: 0px 4px 4px;
    display: flex;
    justify-content: flex-end;
    span {
      cursor: pointer;
    }
  }
`
