/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react' //lazy
import { useHistory } from 'react-router-dom'
import moneyConvert from 'utils/moneyConvert'
import { useDispatch, useSelector } from 'react-redux'
import userName from 'assets/images/ico_login_01.png'

import { Form, Layout } from 'antd'
import { UrlInternal } from 'common/constants/endpoints'
import { loginApi, meApi } from 'apis'
import types from 'store/actions/types'

import * as S from './style'
import { saveLocalStorage } from 'utils/localStorage'

const { Footer } = Layout

interface Props {
  onClick?: any
  className?: any
}

const BoxInfo = (props: Props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { onClick, className } = props
  const dataMe = useSelector((state: any) => state.user)

  const Logout = () => {
    localStorage.removeItem('User')
    localStorage.removeItem('Popup')
    history.push(UrlInternal.LOGIN)
  }
  const onFinish = async (values: any) => {
    try {
      const res: any = await loginApi({
        mb_id: values.username,
        mb_pass: values.password
      })
      // console.log(res);
      if (res.result) {
        saveLocalStorage({
          name: 'User',
          data: res?.access_token
        })
        const resMe: any = await meApi()
        if (resMe.result === false) {
          alert(resMe.message)
          dispatch({ type: 'STOP_WAITTING' }) //dispatch({ type: 'WAITTING' })
        } else {
          dispatch({ type: 'STOP_WAITTING' }) //dispatch({ type: 'WAITTING' })

          dispatch({
            type: types.GET_DATA_USER,
            dataUser: resMe.data
          })
        }
        history.push(UrlInternal.HOME)
        dispatch({ type: 'STOP_WAITTING' }) //dispatch({ type: 'WAITTING' })
      } else {
        alert(res?.error.message)
        dispatch({ type: 'STOP_WAITTING' }) //dispatch({ type: 'WAITTING' })
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: 'STOP_WAITTING' }) //dispatch({ type: 'WAITTING' })
    }
  }
  return (
    <S.Wrapper>
      <S.BoxInfo>
        <div className='member-login'>Member Login</div>
        <S.RightForm>
          <Form
            name='normal_login'
            className='login-form'
            initialValues={{ remember: false }}
            onFinish={onFinish}
          >
            <S.RightFormItem>
              <Form.Item name='username'>
                <input placeholder='아이디' required autoComplete='off' />
              </Form.Item>
            </S.RightFormItem>
            <S.RightFormItem>
              <Form.Item name='password'>
                <input type='password' placeholder='비밀번호' required />
              </Form.Item>
            </S.RightFormItem>

            <Form.Item>
              <S.RightButton
                type='primary'
                htmlType='submit'
                className='login-form-button'
              >
                로그인
              </S.RightButton>
            </Form.Item>
            <S.RightButton
              className='signup-btn'
              onClick={() => history.push(UrlInternal.REGISTER)}
            >
              회원가입
            </S.RightButton>
          </Form>
        </S.RightForm>
      </S.BoxInfo>
      <S.ButtonBox>
        <button
          className='bt pri'
          onClick={() => history.push(UrlInternal.LOGIN)}
        >
          쪽지 <span> 0 </span>
        </button>
        <button
          className='bt pri'
          onClick={() => history.push(UrlInternal.LOGIN)}
        >
          1:1 문의 <span> 0 </span>
        </button>
        <button
          className='bt pri'
          style={{ width: '62px' }}
          onClick={() => history.push(UrlInternal.LOGIN)}
        >
          출석부
        </button>
        <button
          className='bt pri pri1'
          onClick={() => history.push(UrlInternal.LOGIN)}
        >
          룰렛 <span>0</span>
        </button>
        <button
          className='bt pri pri1'
          onClick={() => history.push(UrlInternal.LOGIN)}
        >
          황금 룰렛 <span>0</span>
        </button>
      </S.ButtonBox>
    </S.Wrapper>
  )
}

export default BoxInfo
