import { getHistoryBetting } from 'apis'

export const fetchHistoryBetting = (params: any) => {
  return async (dispatch: any) => {
    dispatch({ type: 'STOP_WAITTING' }) //dispatch({ type: 'WAITTING' })

    try {
      const response: any = await getHistoryBetting(params)
      if (response.data) {
        dispatch({
          type: 'FETCH_HISTORY_DETAIL_SUCCESS',
          payload: response
        })
      }
      dispatch({
        type: 'FETCH_HISTORY_DETAIL_FAILURE',
        payload: response.message || ''
      })
      dispatch({ type: 'STOP_WAITTING' }) //dispatch({ type: 'WAITTING' })
    } catch (error: any) {
      dispatch({
        type: 'FETCH_HISTORY_DETAIL_FAILURE',
        payload: error.message
      })
      dispatch({ type: 'STOP_WAITTING' }) //dispatch({ type: 'WAITTING' })
    }
  }
}
