import React, { useState, useEffect } from "react"; //lazy
import { useHistory } from "react-router-dom";
import moneyConvert from "utils/moneyConvert";
import { useDispatch, useSelector } from "react-redux";
import iconCoin from 'assets/icons/coin_01.png'
import { Layout } from "antd";
import { UrlInternal } from "common/constants/endpoints";
// import { ArrowUpOutlined } from "@ant-design/icons";
import { meApi } from "apis";
import types from "store/actions/types";

import * as S from "./style";

const { Footer } = Layout;

interface Props {
  onClick?: any;
  className?: any;
}

const BoxInfoUser = (props: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { onClick, className } = props;
  const dataMe = useSelector((state: any) => state.user);

  const Logout = () => {
    localStorage.removeItem("User");
    localStorage.removeItem("Popup");
    history.push(UrlInternal.HOME);
  };
  return (
    <S.Wrapper>
      <S.BoxInfo>
        <div
          className="item label"
          onClick={() => history.push(UrlInternal.USER)}
        >
          {dataMe.dataUser?.mb_name}
        </div>
        <div className="item infos">
          <em className="money">
            <img src={iconCoin} alt=''/>
          </em>
          <span className="info">
            보유머니
            <span className="value">
              {moneyConvert(dataMe.dataUser?.mb_money, true)}
            </span>
            원
          </span>
        </div>
        <div
          className="item infos"
          onClick={() => history.push(UrlInternal.POINT)}
        >
          <em className="money">
            <img src={iconCoin} alt=''/>
          </em>
          <span className="info">
            포인트
            <span className="value">
              {moneyConvert(dataMe.dataUser?.mb_point, true)}
            </span>
            점<i className="fa fa-recycle text-warning"></i>전환
          </span>
        </div>
      </S.BoxInfo>
      <S.ButtonBox>
        <button
          className='bt pri'
          onClick={() => history.push(UrlInternal.MESSAGE)}
        >
          쪽지 <span> {dataMe.dataUser?.count_message? dataMe.dataUser?.count_message : 0} </span>
        </button>
        <button
          className='bt pri'
          onClick={() => history.push(UrlInternal.CUSTOMER_CENTER)}
        >
          1:1 문의 <span> {dataMe.dataUser?.count_cscenter? dataMe.dataUser?.count_cscenter : 0} </span>
        </button>
        <button
          className='bt pri' style={{width: '62px'}}
          onClick={() => history.push(UrlInternal.CALENDAR)}
        >
         출석부
        </button>
        <button
          className='bt pri pri1'
          onClick={() => history.push(UrlInternal.ROULETTE)}
        >
         룰렛 <span>0</span>
        </button>
        <button
          className='bt pri pri1'
          onClick={() => history.push(UrlInternal.ROULETTEGOLD)}
        >
         황금 룰렛  <span>0</span>
        </button>
      </S.ButtonBox>
      <S.ButtonGroup>
      <S.RightButtonLogout onClick={Logout} className='logout-btn'>로그아웃</S.RightButtonLogout>
      <S.RightButton className='signup-btn' onClick={()=>history.push(UrlInternal.DEPOSIT)}>충전하기</S.RightButton>
      <S.RightButton className='signup-btn' onClick={()=>history.push(UrlInternal.PAYMENT)}>환전하기</S.RightButton>
      </S.ButtonGroup>
    </S.Wrapper>
  );
};

export default BoxInfoUser;
