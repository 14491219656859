import styled from 'styled-components'
import theme from 'common/style/styleSetup'

export const Wrapper = styled.div`
  width: 100%;
  padding: 14px 0px;
  border-radius: 5px;
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
`

export const Item = styled.div`
  background: #0e2d2a;
  height: 35px;
  padding: 7px 10px;
  cursor: pointer;
  line-height: 20px;
  font-size: 12px;
  transition: 200ms all;
  border-top: solid 1px #273248;
  border-bottom: solid 1px #0f131d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 3px;
  border-radius: 3px;
  :hover {
    background: #00ecff;
  }

  .left {
    display: flex;
    align-items: center;
    gap: 8px;
    .icon {
      img {
        width: 20px;
      }
    }
    .label {
      font-size: 12px;
      color: ${theme.color[0]};
    }
  }
  .right {
    width: 40px;
    height: 22px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #d9fafd;
    font-weight: bold;
    text-align: center;
    background: #00000040;
    padding: 2px 2px 0;
    border: solid 1px #00000050;
  }
`
export const Label = styled.div`
  // border: 1px solid #0078ae;
  // border: 1px solid #ad0029;
  border-radius: 5px;
  height: 40px;
  margin-bottom: 7px;
  font-size: 12px;
  line-height: 36px;
  padding: 0px 10px;
  position: relative;
  font-weight: bold;
  // box-shadow: inset 0px 0px 10px 10px hsl(178deg 100% 50% / 15%);
  // background: linear-gradient(to top, #090d0e, #134e5e);
  background: rgb(13 10 39);
  color: white;
  a {
    color: #c9edea;
  }
`
