/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react' //lazy

import { popupAPI } from 'apis'

import * as S from './popup'

const PopupConfirm = () => {
  const [popup, setPopup] = useState<any>([])
  const [arrayPopup, setArrayPopup] = useState<any>([])
  const [arrayPopupHidden, setArrayPopupHidden] = useState<any>([])
  const getPopup = async () => {
    const res: any = await popupAPI() // Đảm bảo bạn đã xử lý lấy dữ liệu popup ở đây
    if (res) {
      setPopup(res?.data)
      let arr: any = []
      let arrHidden: any = []
      res?.data?.forEach((v: any) => {
        const findPopup = localStorage.getItem(`Popup_${v.id}`)
        if (findPopup === 'true' || findPopup === 'false') {
          localStorage.setItem(`Popup_${v.id}`, 'true')
          arr.push(`Popup_${v.id}`)
        }
        if (findPopup !== 'true' && findPopup !== 'false') {
          arrHidden.push(`Popup_${v.id}`)
        }
        if (!findPopup) {
          localStorage.setItem(`Popup_${v.id}`, 'true')
          arr.push(`Popup_${v.id}`)
        }
      })
      setArrayPopup(arr)
      setArrayPopupHidden(arrHidden)
    }
  }

  useEffect(() => {
    getPopup()
  }, [])
  useEffect(() => {}, [arrayPopup])
  useEffect(() => {
    if (arrayPopupHidden?.length !== 0) {
      arrayPopupHidden?.map((v: any) => {
        const currentTime = new Date().getTime()
        const oneDayInMillis = 24 * 60 * 60 * 1000
        const lastExecutionTime: any = localStorage.getItem(`${v}`)
        const dateClick = new Date(lastExecutionTime).getTime()
        if (currentTime - dateClick >= oneDayInMillis) {
          localStorage.setItem(`${v}`, 'true')
          getPopup()
        }
      })
    }
  }, [arrayPopupHidden])
  const close = (i: any) => {
    let arr: any = []
    localStorage.setItem(`Popup_${i}`, 'false')
    popup.forEach((v: any) => {
      const findPopup = localStorage.getItem(`Popup_${v.id}`)
      if (findPopup === 'true') {
        arr.push(`Popup_${v.id}`)
      }
    })
    setArrayPopup(arr)
  }
  const removeLocalStorage = (id: number) => {
    localStorage.setItem(`Popup_${id}`, new Date().toLocaleDateString())
    const newArray = arrayPopup.filter((item: any) => item !== `Popup_${id}`)
    setArrayPopup(newArray)
  }
  
  return (
    <S.Container>
      {popup?.map((v: any, i: any) => {
        if (arrayPopup?.includes(`Popup_${v.id}`)) {
          return (
            <div className='popup' key={i}>
              <div
                className='img'
                dangerouslySetInnerHTML={{ __html: v?.board_content }}
              />
              <div className='content'>
                <div
                  className='content-text'
                  onClick={close}
                  dangerouslySetInnerHTML={{ __html: v?.board_subject }}
                ></div>
                <div className='check-box'>
                  <div
                    className='box'
                    onClick={() => {
                      removeLocalStorage(v.id)
                    }}
                  >
                    {' '}
                  </div>
                  <div className='text'>
                    &ensp;&emsp; 오늘은 그만 보고싶습니다.
                  </div>
                </div>
              </div>
              <div className='button'>
                <span onClick={() => close(v.id)}>CLOSE</span>
              </div>
            </div>
          )
        }
      })}
    </S.Container>
  )
}
export default PopupConfirm
