import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import * as S from './style'
import './style.css'
import types from 'store/actions/types'
import userName from 'assets/images/ico_login_01.png'
import passWord from 'assets/images/ico_password_01.png';

import { loginApi, meApi } from 'apis'
import { saveLocalStorage } from 'utils/localStorage'
import { UrlInternal } from 'common/constants/endpoints'
import { useHistory } from 'react-router-dom'
const LoginModal = () => {
  const isOpenLogin = useSelector((state: any) => state.loginOpen.isOpenLogin)
  const dispatch = useDispatch()
  const history = useHistory()
  const [isCheck, setIsCheck] = useState<any>(false)
 
  const userNameSaved = localStorage.getItem('username')
  const onFinish = async (values: any) => {
    const username = values.username !== undefined ? values.username : userNameSaved
    try {
      const res: any = await loginApi({
        mb_id: username,
        mb_pass: values.password
      })
      // console.log(res);
      if (res.result) {
        saveLocalStorage({
          name: 'User',
          data: res?.access_token
        })
        if(isCheck){
          localStorage.setItem('username', values.username)
        }
        const resMe: any = await meApi()
        if (resMe.result === false) {
          alert(resMe.message)
          dispatch({ type: 'STOP_WAITTING' })
        } else {
          dispatch({ type: 'STOP_WAITTING' })
          dispatch({type: 'CLOSE_LOGIN' })
          dispatch({
            type: types.GET_DATA_USER,
            dataUser: resMe.data
          })
        }
        history.push(UrlInternal.HOME)
        dispatch({ type: 'STOP_WAITTING' })
      } else {
        alert(res?.error.message)
        dispatch({ type: 'STOP_WAITTING' })
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: 'STOP_WAITTING' })
    }
  }
  const handleCancel = () => {
    dispatch({ type: 'CLOSE_LOGIN' })
  }
  return (
    <Modal
      title='Member Login'
      open={isOpenLogin}
      onCancel={handleCancel}
      className='login-modal'
    >
      <Form
        name='normal_login'
        className='login-form'
        initialValues={{ remember: false }}
        onFinish={onFinish}
      >
        <S.RightFormItem>
          <img src={userName} alt='' className='imgIcon' />
          <Form.Item name='username'>
            <input placeholder='아이디'  defaultValue={userNameSaved || ''} required autoComplete='on' />
          </Form.Item>
        </S.RightFormItem>
        <S.RightFormItem>
          <img src={passWord} alt='' className='imgIcon' />
          <Form.Item name='password'>
            <input type='password' placeholder='비밀번호' required />
          </Form.Item>

        </S.RightFormItem>
        <S.RightCheckItem>
          <Form.Item>
            <input type='checkbox' checked={isCheck} onChange={(e)=>setIsCheck(e.target.checked)} />
            <p>아이디저장</p>
          </Form.Item>
        </S.RightCheckItem>
        <Form.Item>
          <S.RightButton
            type='primary'
            htmlType='submit'
            className='login-form-button'
          >
            로그인
          </S.RightButton>
        </Form.Item>
        <S.Register onClick={()=>history.push(UrlInternal.REGISTER)}>
          <div>
            아직도 회원가입을 안하셨나요? 회원가입
          </div>
        </S.Register>
      </Form>
    </Modal>
  )
}

export default LoginModal
