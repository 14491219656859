import { mainAxios } from 'libs/axios'

export const slotGameApi = () => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/casino/as?code=evoplay`,
    // params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface SlotGameDetailProps {
  vendor: string
  game_id: string
}

export const slotGameDetailApi = (payload: SlotGameDetailProps) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/casino/as/launch`,
    params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

export const getListSlot = () => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/cx/slot`,
    // params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}
export const getListSlotLobby =(type : string)=>{
  return mainAxios.request({
    methodType: 'GET',
    url: `/${type}/slot`,
    // params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}
interface SlotTypeProps {
  type: string
  code: string | number
}

export const getSlotType = (payload: SlotTypeProps, type: string) => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/${type}/slot/gamelist`,
    params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface LaunchProps {
  vendor: string
  type: string
  game_code: string
}

export const getLaunchSlot = (payload: LaunchProps) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/cx/launch`,
    payload: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}
