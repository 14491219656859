import React, { useEffect, useMemo, useState, useRef } from 'react' //lazy
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Divider, Menu, Switch } from 'antd'
import type { MenuProps, MenuTheme } from 'antd/es/menu'

import icon1 from 'assets/images/header-icons/Icon_1.png'
import icon2 from 'assets/images/header-icons/Icon_2.png'
import icon3 from 'assets/images/header-icons/Icon_3.png'
import icon4 from 'assets/images/header-icons/Icon_5.png'
import icon5 from 'assets/images/header-icons/Icon_6.png'
import icon6 from 'assets/images/header-icons/Icon_7.png'
import icon7 from 'assets/images/header-icons/Icon_9.png'
import icon8 from 'assets/images/header-icons/Icon_10.png'
import icon9 from 'assets/images/header-icons/Icon_12.png'
import icon10 from 'assets/images/header-icons/Icon_13.png'
import iconClose from 'assets/images/ico_close_01.png'

import { UrlInternal } from 'common/constants/endpoints'
import logo from 'assets/images/login/ILOVEYOU_Logo1.png'
import './style.css'
import avatar from 'assets/images/avatar.png'
import * as S from './style'
import changePoint from 'assets/images/Icon.png'
import { MessageFilled } from '@ant-design/icons'
import LoginModal from 'pages/ModalLogin'
import BoxInfoUser from 'components/Menu/BoxInfoUser'
import { BoxInfo, BestSlipMiniGame, CasinoList } from 'components'

interface Props {
  onClick?: any
  className?: any
}
type MenuItem = Required<MenuProps>['items'][number]

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  // icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    // icon,
    children,
    label
  } as MenuItem
}

const items1: MenuItem[] = [
  getItem('스포츠 ▼', 'sub1', [
    getItem('크로스', '/sports/detail/0'),
    getItem('스페셜', '/sports/detail/3'),
    getItem('실시간', '/sports/detail/r')
  ]),
  getItem('라이브카지노', '/live'),
  getItem('슬롯게임', '/rng'),
  getItem('로투스', '/mini_lobby/detail/ps'),
  getItem('미니게임', '/mini_lobby/detail/c3')
  // getItem(
  //   <a href='https://ant.design' target='_blank' rel='noopener noreferrer'>
  //     Ant Design
  //   </a>,
  //   'link'
  // )
]
const items2: MenuItem[] = [
  getItem('배팅내역 ▼', '배팅내역 ▼', [
    getItem('스포츠', '/history-betting-solo'),
    getItem('실시간', '/history-betting-power')
  ]),
  getItem('고객센터', '/customer-center'),
  getItem('이벤트', '/event'),
  getItem('공지사항&게시판', '/notification')
]

const HeaderPage = (props: Props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const menuRef = useRef(null)
  const userRef = useRef(null)
  const userData: any = localStorage.getItem('User')
  const { onClick, className } = props
  const dataMe = useSelector((state: any) => state.user)
  const [menuList, setMenuList] = useState<boolean>(false)
  const [userList, setUserList] = useState<boolean>(false)
  const isWaitting = useSelector((state: any) => state.waitting)
  const dataUser = localStorage.getItem('User')

  useOutsideUser(userRef)
  function useOutsideUser(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref?.current?.contains(event.target)) {
          setUserList(false)
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }
  useOutsideMenu(menuRef)
  function useOutsideMenu(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref?.current?.contains(event.target)) {
          setMenuList(false)
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const Logout = () => {
    localStorage.removeItem('User')
    localStorage.removeItem('Popup')
    history.push(UrlInternal.LOGIN)
  }

  useEffect(() => {}, [dataMe])
  const content = (
    <div style={{ margin: '0px 10px', minWidth: '250px' }}>
      <S.Row>
        {dataMe.dataUser && (
          <S.Avatar onClick={() => history.push(UrlInternal.USER)}>
            <S.AvatarImg>
              <img src={avatar} alt='avatar' />
            </S.AvatarImg>
            <p style={{ color: '#FAAD14' }}>{dataMe.dataUser.mb_name}</p>
          </S.Avatar>
        )}
        <S.Info>
          <p>
            보유머니: <span>{dataMe.dataUser.mb_money}</span> 원
          </p>
          <p onClick={() => history.push(UrlInternal.POINT)}>
            포인트: <span>{dataMe.dataUser.mb_point}</span> 점{' '}
            <img src={changePoint} /> 전환{' '}
          </p>
        </S.Info>
      </S.Row>
      <S.Content onClick={() => history.push(UrlInternal.CUSTOMER_CENTER)}>
        <p style={{ color: 'yellow' }}>고객센터</p>
      </S.Content>
      <S.Content onClick={() => history.push(UrlInternal.MESSAGE)}>
        <p style={{ color: 'yellow' }}>쪽지</p>
      </S.Content>
      <S.Content onClick={Logout}>
        <p style={{ color: 'yellow' }}>로그아웃</p>
      </S.Content>
    </div>
  )
  const handleClick = (url: string) => {
    if (dataUser) {
      history.push(url)
    } else {
      dispatch({ type: 'OPEN_LOGIN' })
    }
  }
  const reload = (url: string) => {
    history.push(url)
    window.location.reload()
  }

  useEffect(() => {}, [dataUser])
  return (
    <>
      <S.Header>
        <S.WrapperTop>
          <S.TopIcon>
            <div className='icon' onClick={() => setMenuList(!menuList)}>
              <svg
                version='1.1'
                xmlns='http://www.w3.org/2000/svg'
                // xmlns:xlink='http://www.w3.org/1999/xlink'
                width='17'
                height='17'
                viewBox='0 0 17 17'
              >
                <g></g>
                <path
                  d='M16 2v2h-11v-2h11zM5 9h11v-2h-11v2zM5 14h11v-2h-11v2zM2 2c-0.552 0-1 0.447-1 1s0.448 1 1 1 1-0.447 1-1-0.448-1-1-1zM2 7c-0.552 0-1 0.447-1 1s0.448 1 1 1 1-0.447 1-1-0.448-1-1-1zM2 12c-0.552 0-1 0.447-1 1s0.448 1 1 1 1-0.447 1-1-0.448-1-1-1z'
                  fill='#fff'
                />
              </svg>
            </div>
            {/* <FontAwesomeIcon icon={icon({ name: 'user-secret' })} /> */}
          </S.TopIcon>
          <S.TopLogo onClick={() => reload(UrlInternal.HOME)}>
            <img alt='logo' src={logo} />
          </S.TopLogo>
          <S.TopButton>
            <S.ItemButton onClick={() => handleClick('/sports/detail/0')}>
              <img src={icon1} alt='icon' />
              스포츠
            </S.ItemButton>
            <S.ItemButton onClick={() => handleClick('/sports/detail/3')}>
              <img src={icon2} alt='icon' />
              스페셜
            </S.ItemButton>
            <S.ItemButton onClick={() => handleClick('/sports/detail/r')}>
              <img src={icon3} alt='icon' />
              실시간
            </S.ItemButton>
            <S.ItemButton onClick={() => handleClick('/mini_lobby/detail/ps')}>
              <img src={icon4} alt='icon' />
              미니게임
            </S.ItemButton>
            <S.ItemButton onClick={() => handleClick('/live')}>
              <img src={icon5} alt='icon' />
              라이브카지노
            </S.ItemButton>
            <S.ItemButton onClick={() => handleClick('/rng')}>
              <img src={icon6} alt='icon' />
              슬롯게임
            </S.ItemButton>
            <S.ItemButton>
              <img src={icon5} alt='icon' />
              로투스
            </S.ItemButton>
            <S.ItemButton onClick={() => handleClick('/history-betting-solo')}>
              <img src={icon7} alt='icon' />
              배팅내역
            </S.ItemButton>
            <S.ItemButton onClick={() => handleClick('/customer-center')}>
              <img src={icon8} alt='icon' />
              고객센터
            </S.ItemButton>
            <S.ItemButton onClick={() => handleClick(UrlInternal.EVENT)}>
              <img src={icon9} alt='icon' />
              이벤트
            </S.ItemButton>
            <S.ItemButton onClick={() => handleClick(UrlInternal.NOTIFICATION)}>
              <img src={icon10} alt='icon' />
              공지사항&게시판
            </S.ItemButton>
          </S.TopButton>
          <S.TopIcon>
            <div className='icon' onClick={() => setUserList(!userList)}>
              <svg
                version='1.1'
                xmlns='http://www.w3.org/2000/svg'
                // xmlns:xlink='http://www.w3.org/1999/xlink'
                width='17'
                height='17'
                viewBox='0 0 17 17'
              >
                <g></g>
                <path
                  d='M17 16.488c-0.063-2.687-2.778-4.999-6.521-5.609v-1.374c0.492-0.473 0.842-1.207 1.071-1.833 0.332-0.166 0.624-0.536 0.794-1.033 0.238-0.688 0.146-1.323-0.206-1.629 0.028-0.238 0.046-0.481 0.015-0.723-0.079-0.663 0.065-1.038 0.194-1.368 0.106-0.277 0.229-0.591 0.106-0.945-0.442-1.273-1.727-1.974-3.618-1.974l-0.264 0.005c-1.313 0.047-1.707 0.6-1.971 1.115-0.033 0.062-0.077 0.146-0.077 0.151-1.712 0.153-1.697 1.569-1.684 2.707l0.003 0.369c0 0.205 0.009 0.419 0.026 0.639-0.425 0.3-0.504 1.005-0.179 1.737 0.185 0.415 0.452 0.729 0.749 0.892 0.243 0.674 0.625 1.47 1.179 1.965v1.283c-3.798 0.589-6.554 2.907-6.617 5.625l-0.012 0.512h17.023l-0.011-0.512zM1.054 16c0.392-2.094 2.859-3.821 6.122-4.204l0.441-0.052v-2.666l-0.216-0.15c-0.393-0.272-0.791-0.947-1.090-1.851l-0.083-0.281-0.294-0.051c-0.053-0.019-0.208-0.153-0.33-0.428-0.075-0.168-0.104-0.312-0.112-0.415l0.51 0.143-0.096-0.749c-0.042-0.33-0.064-0.651-0.064-0.95l-0.003-0.38c-0.015-1.341 0.051-1.634 0.773-1.699 0.545-0.048 0.752-0.449 0.876-0.689 0.15-0.292 0.28-0.543 1.12-0.574l0.227-0.004c0.829 0 2.279 0.169 2.669 1.282 0 0.043-0.052 0.177-0.090 0.275-0.145 0.374-0.364 0.939-0.254 1.853 0.024 0.188-0.007 0.424-0.040 0.675l-0.089 0.805 0.441-0.048c0.008 0.104-0.004 0.269-0.075 0.472-0.097 0.289-0.242 0.438-0.237 0.454h-0.36l-0.114 0.342c-0.283 0.853-0.65 1.497-1.009 1.768l-0.198 0.15v2.726l0.438 0.055c3.211 0.401 5.641 2.123 6.030 4.192h-14.893z'
                  fill='#ffffff'
                />
              </svg>
            </div>
          </S.TopIcon>
        </S.WrapperTop>
        <LoginModal />
      </S.Header>
      {/* {menuList && ( */}
      <S.MenuContent className={menuList ? 'active' : ''}>
        <div ref={menuRef}>
          <S.MenuContentItem>
            <img
              alt=''
              src={iconClose}
              className='icon-close'
              onClick={() => setMenuList(false)}
            />
            <Menu
              onClick={(e: any) => {
                handleClick(e.key)
                setMenuList(false)
              }}
              style={{ width: 256 }}
              // defaultSelectedKeys={[]}
              // defaultOpenKeys={[]}
              mode='inline'
              items={items1}
            />
            <Menu
              className='menu-222'
              onClick={(e: any) => {
                handleClick(e.key)
                setMenuList(false)
              }}
              style={{ width: 256 }}
              // defaultSelectedKeys={[]}
              // defaultOpenKeys={[]}
              mode='inline'
              items={items2}
            />
          </S.MenuContentItem>
        </div>
      </S.MenuContent>
      {/* )} */}
      {/* {userList && ( */}
      <S.UserContent className={userList ? 'active' : ''}>
        <div ref={userRef}>
          <S.MenuContentItem>
            <img
              alt=''
              src={iconClose}
              className='icon-close'
              onClick={() => setUserList(false)}
            />
            {userData ? <BoxInfoUser /> : <BoxInfo />}
            <BestSlipMiniGame />
            <CasinoList />
          </S.MenuContentItem>
        </div>
      </S.UserContent>
      {/* )} */}
    </>
  )
}

export default HeaderPage
