import types from '../actions/types'

const defaultState = {
  isOpenLogin: false
}

export default (state = defaultState, action: any) => {
  const type = action?.type

  switch (type) {
    case types.OPEN_LOGIN:
      console.log(state.isOpenLogin)
      return { ...state, type, isOpenLogin: true, ...action?.payload }
    case types.CLOSE_LOGIN:
      return { ...state, type, isOpenLogin: false, ...action?.payload }
    default:
      return state
  }
}
