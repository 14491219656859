import { Button } from 'antd'
import styled, { keyframes } from 'styled-components'
import theme from "common/style/styleSetup";

const shinyButtonAnimation = keyframes`
0% {
  opacity: 0.5;
  // transform: translateX(-150%);
  transform: translateX(-300%) rotate(120deg) scale(1);
}
25%{
  opacity: 0.4;
  // transform: translateX(-150%);
  transform: translateX(-200%) rotate(120deg) scale(2);
}
50% {
  opacity: 1;
  // transform: translateX(0%);
  transform: translateX(100%) rotate(120deg) scale(3);

}
75%{
  opacity: 0.4;
  // transform: translateX(-150%);
  transform: translateX(200%) rotate(120deg) scale(2);
}
100% {
  opacity: 0;
  // transform: translateX(100%);
  transform: translateX(300%) rotate(120deg) scale(1);

}
animation-duration: 3s;
animation-timing-function: ease-in-out;
animation-delay: 0s;
animation-iteration-count: infinite;
animation-direction: normal;
animation-fill-mode: none;
animation-play-state: running;
animation-name: shiny-btn1;
animation-timeline: auto;
animation-range-start: normal;
animation-range-end: normal;
`
export const Wrapper = styled.div`
  width: 100%;
  background: #3a3a42;
  border: solid 1px rgba(255, 255, 255, 0.04);
  padding: 0px;
  -webkit-border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
`

export const Header = styled.div`
  width: 100%;
  padding: 5px;
  .alert-success {
    display: flex;
    flex-direction: column;
    padding: 15px 0px 10px 0px;
    width: 50%;
    line-height: 7px;
    span {
      color: white;
    }
  }
  .header {
    display: flex;
    justify-content: flex-start;
    background: #2e2e35;
    padding: 5px;
    img {
      margin-left: 10px;
      width: 27px;
      height: 28px;
    }
  }
  p {
    font-family: 'SCDream4';
    font-size: 14px;
    font-weight: 700;
    padding: 5px;
    color: #00fff3;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    line-height: 25px;
    margin: 0;
  }

  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .clock {
      color: #02a6a7;
      font-size: 16px;
      width: 50%;
      text-align: center;
    }

    .btn {
      width: fit-content;
      margin: auto;
    }
  }
`

export const Betting = styled.div`
  width: 100%;
  padding: 0px 5px;
`

export const Table = styled.table`
  margin: 0;
  border-collapse: inherit;
  width: 100%;
  border-spacing: 0;
  word-break: break-all;
`

export const Tbody = styled.tbody`
  width: 100%;

  tr {
    th {
      margin: 0px;
      padding: 0px;
      font-size: 12px;
      color: #fff;
      line-height: 100%;
      font-weight: 400;
      box-sizing: border-box;
    }

    td {
      width: 100%;
      height: 40px;
      line-height: 35px;

      p {
        margin: 0px;
      }

      .num {
        color: #02a6a7;
        font-size: 15px;
        text-align: center;
      }

      .bg_p,
      .betmoney {
        text-align: right;
        width: 100%;
        height: 35px;
        line-height: 35px;
        border: 1px solid #444444;
        background: #171717;
        font-size: 14px;
        vertical-align: middle;
        padding: 0 20px;
        color: #fff;
        transition: 0.5s;

        &.red {
          color: #ff0000;
        }
      }
    }
  }
`

export const ButtonWrapper = styled.div`
  width: 100%;
  padding: 5px;
`

export const ButtonMoneyWrappper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: auto;
  gap: 5px;
  padding-bottom: 5px;
`
export const ButtonResetWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 5px;
`

export const ButtonSubmitWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  position: relative;
  .button-bet{
    position: relative;
    ::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0;
      width: 343px;
      height: 122px;
      background-color: #fff;
      animation: ${shinyButtonAnimation} 3s ease-in-out infinite;
    }
  }
`

export const ListBetting = styled.div`
  width: 100%;
  padding-left: 15px;
  color: #fff;
  font-size: 12px;
  .inner {
    width: 100%;

    .top {
      display: flex;
      flex-direction: column;
      width: 100%;
      p {
        margin: 0;
        white-space: nowrap;
        font-size: 12px;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .bottom {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .btn {
        cursor: pointer;
        height: 22px;
        background: #c82323;
        border-radius: 1px;
        padding: 2px 10px 0;
        font-weight: 400;
      }
    }
  }
`
export const Folder = styled.div`
  margin-top: 10px;
  p {
    font-weight: 600
  }
`
export const RightButton = styled(Button)`
  width: 100%;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: 0.3s;
  background: linear-gradient(to top, #00757d, #0078ae);
  box-shadow: 4px 7px 12px 0 rgb(119 80 248 / 20%);
  font-size: 13px;
  color: ${theme.color[0]};
  box-shadow: inset 2px 2px 2px 0px rgba(255,255,255,.5), 7px 7px 20px 0px rgba(0,0,0,.1), 4px 4px 5px 0px rgba(0,0,0,.1);
  transition: all 0.3s ease;
  background-size: 100% 200%; 
  background-position: 0% 100%;
  border: none;
  z-index: 1;
  background: linear-gradient(to top, #00757d, #0078ae);
  
  :hover {
    color: black !important;
  }
  :hover::after {
    top: auto;
    bottom: 0;
    height: 100%;
  }
  ::after {
    color:black !important;
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 5px;
    background-color: #c9edea;
    background-image: linear-gradient(315deg,#c9edea 0%,#f6fc9c 74%);
    box-shadow: inset 2px 2px 2px 0px rgba(255,255,255,.5);
    7px 7px 20px 0px rgba(0,0,0,.1),4px 4px 5px 0px rgba(0,0,0,.1): ;
    transition: all 0.3s ease;
`
