import React, { useEffect, useState } from 'react' //lazy

import { Layout } from 'antd'
import { UrlInternal } from 'common/constants/endpoints'
// import { ArrowUpOutlined } from "@ant-design/icons";

// import { UrlInternal } from "common/constants/endpoints";
import Item from './item'

import { miniAllApi } from 'apis'

import * as S from './style'
import { useDispatch, useSelector } from 'react-redux'
import LoginModal from 'pages/ModalLogin'
import { useHistory } from 'react-router-dom'

interface Props {
  onClick?: any
  className?: any
}

const ListGame = (props: Props) => {
  const { onClick, className } = props
  const userData: any = localStorage.getItem('User')
  const history = useHistory()
  const path = window.location.pathname;
  const dispatch = useDispatch()
  const [dataGame, setDataGame] = useState<any>([])
  const [lock, setLock] = useState<boolean>(true)
  const { sportsGameList } = useSelector((state: any) => state?.sportsGameList)

  const getMiniGame = async () => {
    try {
      const res: any = await miniAllApi()
      // console.log(res);
      if (res.result === false) {
        alert(res.message)
      } else {
        setDataGame(res)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleClick = (e: any, category: string, type: string) => {
    if(userData){
      const newUrl = new URLSearchParams(window.location.search);
      newUrl.set("category", category);
      if (path.includes(UrlInternal.SPORT)) {
        history.push(`${window.location.pathname}?${newUrl.toString()}`);
      } else {
        history.push(
          `${UrlInternal.SPORTS_DETAIL_ROUTER}${type}?${newUrl.toString()}`
        );
      }
    }
    else{
      dispatch({type: 'OPEN_LOGIN'})
    }
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     getMiniGame()
  //   }, 30000)
  //   return () => clearInterval(interval)
  // }, [])
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1
    const day = String(today.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  }
  
    const currentDate : any = getCurrentDate();
  return (
    <S.Wrapper>
      <S.Content>
      <S.Label>
        오늘의 경기 <a> Today Match</a> &nbsp;&nbsp;&nbsp;<span>{currentDate}</span>
      </S.Label>
         {sportsGameList?.map((v: any, i: number) => (
          <div onClick={(e) => handleClick(e, v?.category, v?.type)} key={i} >
            <Item data={v} />
          </div>
        ))}
      </S.Content>
      <LoginModal/>
    </S.Wrapper>
  )
}

export default ListGame
