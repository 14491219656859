import { mainAxios } from 'libs/axios'

export const casinoListApi = () => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/casino/as`,
    // params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

export const getListCasino = () => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/cx/casino`,
    // params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}
export const getListCasinoLobby =(type : string)=>{
  return mainAxios.request({
    methodType: 'GET',
    url: `/${type}/casino`,
    // params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}
export const getType = () => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/site/config`,
    // params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}
interface CasinoTypeProps {
  type: string
  code: string | number
}

export const getCasinoType = (payload: CasinoTypeProps) => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/cx/casino/gamelist`,
    params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}
export const getCasinoTypeLobbly = (payload: CasinoTypeProps, type: string) => {
  return mainAxios.request({
    methodType: 'GET',
    url: `/${type}/casino/gamelist`,
    params: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}
interface CasinoDetailProps {
  vendor: string
  game_id: string | number
}

export const casinoGameDetailApi = (payload: CasinoDetailProps, type: string) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/casino/${type}/launch`,
    payload: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}

interface LaunchProps {
  vendor: string
  type: string
  game_code: string
}

export const getLaunchCasino = (payload: LaunchProps, type: string) => {
  return mainAxios.request({
    methodType: 'POST',
    url: `/${type}/launch`,
    payload: payload,
    requiresToken: true,
    notError: true,
    getError: true,
    config: {
      headers: {
        contentType: 'application/json'
      }
    }
  })
}
