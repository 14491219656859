import styled from 'styled-components'
export const Footers = styled.div`
  background: black;
  width: 100%;
  font-size: 12px;
  color: #8b8c8f;
  line-height: 19px;
  text-align: center;
  padding-top: 20px;
  span {
    margin: 0 3px;
    color: #fbbd01;
  }
`
export const Sponsor = styled.div`
  img {
    padding: 4px;
  }
  @media screen and (max-width: 767px) {
    img {
      height: 40px;
    }
  }
`
export const Line = styled.div`
  border-top: 1px solid gray;
  width: 50%;
  margin: 20px auto;
`
export const Text = styled.div`
`
export const Login = styled.div`
  background: #050f32;
  font-size: 14px;
  color: #5581b7;
  line-height: 19px;
  text-align: center;
  margin-top: -170px;
  font-family: Ubuntu;
  font-weight: 400;

`