import React, { useEffect } from 'react' //lazy

import { Layout } from 'antd'
import imgLogo1 from 'assets/images/footer-logo/foot_banner_01.png'
import imgLogo2 from 'assets/images/footer-logo/foot_banner_02.png'
import imgLogo3 from 'assets/images/footer-logo/foot_banner_03.png'
import imgLogo4 from 'assets/images/footer-logo/foot_banner_04.png'
import imgLogo5 from 'assets/images/footer-logo/foot_banner_05.png'
import imgLogo6 from 'assets/images/footer-logo/foot_banner_06.png'
import imgLogo7 from 'assets/images/footer-logo/foot_banner_07.png'
import imgLogo8 from 'assets/images/footer-logo/foot_banner_08.png'
import imgLogo9 from 'assets/images/footer-logo/foot_banner_09.png'
import imgLogo10 from 'assets/images/footer-logo/foot_banner_10.png'
import imgLogo11 from 'assets/images/footer-logo/foot_banner_11.png'
import imgLogo12 from 'assets/images/footer-logo/foot_banner_12.png'
import imgLogo13 from 'assets/images/footer-logo/foot_banner_13.png'
import imgLogo14 from 'assets/images/footer-logo/foot_banner_14.png'
import imgLogo15 from 'assets/images/footer-logo/foot_banner_15.png'
import imgLogo16 from 'assets/images/footer-logo/foot_banner_16.png'
import imgLogo17 from 'assets/images/footer-logo/foot_banner_17.png'
import imgLogo18 from 'assets/images/footer-logo/foot_banner_18.png'
import imgLogo19 from 'assets/images/footer-logo/foot_banner_19.png'
import imgLogo20 from 'assets/images/footer-logo/foot_banner_20.png'
import imgLogo21 from 'assets/images/footer-logo/foot_banner_21.png'
import imgLogo22 from 'assets/images/footer-logo/foot_banner_22.png'
import imgLogo23 from 'assets/images/footer-logo/foot_banner_23.png'
import imgLogo24 from 'assets/images/footer-logo/foot_banner_24.png'
import imgLogo25 from 'assets/images/footer-logo/foot_banner_25.png'
import imgLogo26 from 'assets/images/footer-logo/foot_banner_26.png'
import imgLogo27 from 'assets/images/footer-logo/foot_banner_27.png'
import imgLogo28 from 'assets/images/footer-logo/foot_banner_28.png'


import * as S from './style'
import { useLocation } from 'react-router-dom'

const { Footer } = Layout

interface Props {
  onClick?: any
  className?: any
}

const FooterPage = (props: Props) => {
  const { onClick, className } = props
  const sponsorLogo: any = [
    imgLogo1, imgLogo2, imgLogo3, imgLogo4, imgLogo5,
    imgLogo6, imgLogo7, imgLogo8, imgLogo9, imgLogo10,
    imgLogo11, imgLogo12, imgLogo13, imgLogo14, imgLogo15,
    imgLogo16, imgLogo17, imgLogo18, imgLogo19, imgLogo20,
    imgLogo21, imgLogo22, imgLogo23, imgLogo24, imgLogo25,
    imgLogo26, imgLogo27, imgLogo28

  ]
  const params = useLocation();
  useEffect(() => {
  }, [params])
  return (
    <>
      {params.pathname !== '/login' && params.pathname !== '/register'  &&
        <S.Footers>
          <S.Sponsor>
            {sponsorLogo.map((v: any, i: any) => (
              <img src={v} key={i} alt='logo' />
            ))}
          </S.Sponsor>
          <S.Line></S.Line>
          <S.Text>
            By accessing, continuing to use or navigation throughout this site you accept that we wile use certain browser cookies
            <br />
            to improve your customer experience with us, 한방 only uses cookies which will improve your experience with
            <br />
            us amd will not interfere with your privacy.
            <br />
            COPYRIGHT ©<span>샘플1.</span>
            ALL RIGHTS RESERVED.
          </S.Text>
        </S.Footers>

      }
    </>
  )
}

export default FooterPage
