import React, { useEffect, useState } from 'react' //lazy
import Countdown from 'react-countdown'

import icon from 'assets/images/templates/listGameIcon.png'

import * as S from './style'

interface Props {
  data?: any
  onClick?: any
  className?: any
} // Random component

const Completionist = () => <span>0:00</span>

const ItemMatch = (props: Props) => {
  const { onClick, className, data } = props
  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      return <Completionist />
    } else {
      // Render a countdown
      return (
        <span>
          {minutes}:{seconds > 9 ? seconds : `0${seconds}`}
        </span>
      )
    }
  }
  return (
    <S.ItemMatch>
      <div className='left'>
        <div className='time'>{data?.game_time.split(' ')[1]}</div>
        <div className='icon'>
          <img alt='' src={data?.icon} />
        </div>
        <div className='match'>
            <p><b>{data?.home_name}</b></p>
            <p>{data?.visit_name}</p>
        </div>
      </div>
    </S.ItemMatch>
  )
}

export default ItemMatch
