import styled from 'styled-components'

export const Wrapper = styled.button`
  width: 100%;
  border-radius: 4px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  padding: 10px 0;
  margin-bottom: 0;
  margin-right: 0;
  border: none;
  height: 40px;
  background: rgb(251,33,117);
  background: linear-gradient(0deg,rgba(251,33,117,1)0%,rgba(234,76,137,1)100%);
  overflow: hidden;
  box-shadow: inset 2px 2px 2px 0px rgba(255,255,255,.5), 7px 7px 20px 0px rgba(0,0,0,.1), 4px 4px 5px 0px rgba(0,0,0,.1);
  &:hover {
    opacity: .7;
  }
`
