import styled from "styled-components";
import theme from "common/style/styleSetup";
import common from "assets/images/templates/common.png";
import { Button } from "antd";

export const Wrapper = styled.div`
  width: 100%;
  background: #3A3A42;
  background-color: #3A3A42;
  padding: 4px;
  border-radius: 5px;
  margin-bottom: 10px;
`;

export const BoxInfo = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid #303c56;
  
  .item {
    cursor: pointer;
    height: 30px;
    border: 1px solid #196a6d;
    color: #00fff3;
    background: #1f2123;
    border-radius: 3px;
    font-size: 12px;
    box-shadow: inset 0px 0px 10px 10px hsl(178deg 100% 50% / 15%);
    padding: 0px 20px;
    line-height: 26px;
    font-weight: bold;
    margin-bottom: 4px;
    cursor: pointer;
    position: relative;
    transition: 200ms all;
    .icicon {
      background: url(${common}) no-repeat;
      -webkit-background-size: 304px 944px;
      background-size: 304px 944px;
    }
    .money {
      padding: 0 5px;
    }
    .point .icicon {
      display: inline-block;
      width: 11px;
      height: 11px;
      margin: 0 3px;
      background-position: -69px -48px;
      vertical-align: top;
    }
    &.label {
      height: 30px;
      border: 1px solid #196a6d;
      color: #00fff3;
      background: #1f2123;
      border-radius: 3px;
      font-size: 12px;
      box-shadow: inset 0px 0px 10px 10px hsl(178deg 100% 50% / 15%);
      padding: 0px 20px;
      line-height: 26px;
      font-weight: bold;
      margin-bottom: 4px;
      cursor: pointer;
      position: relative;
      transition: 200ms all;
      :hover {
        background: #00ecff;
        color: yellow;
      }
    }
    &.infos {
      height: 30px;
      border: 1px solid #196a6d;
      color: #00fff3;
      background: #1f2123;
      border-radius: 3px;
      font-size: 12px;
      box-shadow: inset 0px 0px 10px 10px hsl(178deg 100% 50% / 15%);
      padding: 0px 20px;
      line-height: 26px;
      font-weight: bold;
      margin-bottom: 4px;
      :hover {
        background: #00ecff;
        color: yellow
      }
      .value {
        color: #fff;
        margin: 0 4px;
      }
    }
  }
`;

export const ButtonBox = styled.div`
  width: 100%;
  height: auto;
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  span{
    border: 1px solid #02ffe5;
    background: #000000;
    text-align: center;
    display: inline-block;
    line-height: 18px;
    vertical-align: top;
    width: 30px;
    height: 20px;
    border-radius: 2px;
    margin: 2px 0px 0px 4px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 3px;
  }
  .pri1{
    width: 122px;
  }
  .bt {
    color: #fff;
    border: 1px solid #41b3ae;
    background: #23221d;
    border-radius: 3px;
    font-size: 12px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 3px;
    float: left;
    line-height: 26px;
    font-weight: bold;
    margin-bottom: 4px;
    cursor: pointer;
    position: relative;
    transition: 200ms all;
    :hover {
      background: #00ecff;
    }
  }
`
export const RightButton = styled(Button)`
  width: 100%;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: 0.3s;
  background: linear-gradient(to top, #00757d, #0078ae);
  box-shadow: 4px 7px 12px 0 rgb(119 80 248 / 20%);
  font-size: 13px;
  color: ${theme.color[0]};
  box-shadow: inset 2px 2px 2px 0px rgba(255,255,255,.5), 7px 7px 20px 0px rgba(0,0,0,.1), 4px 4px 5px 0px rgba(0,0,0,.1);
  transition: all 0.3s ease;
  background-size: 100% 200%; 
  background-position: 0% 100%;
  border: none;
  z-index: 1;
  background: linear-gradient(to top, #00757d, #0078ae);
  
  :hover {
    color: black !important;
  }
  :hover::after {
    top: auto;
    bottom: 0;
    height: 100%;
  }
  ::after {
    color:black !important;
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    z-index: -1;
    border-radius: 5px;
    background-color: #c9edea;
    background-image: linear-gradient(315deg,#c9edea 0%,#f6fc9c 74%);
    box-shadow: inset 2px 2px 2px 0px rgba(255,255,255,.5);
    7px 7px 20px 0px rgba(0,0,0,.1),4px 4px 5px 0px rgba(0,0,0,.1): ;
    transition: all 0.3s ease;
`
export const RightButtonLogout = styled(Button)`
  border: 1px solid #ff0012 !important;
  background: #501a1a !important;
  box-shadow: inset 0px 0px 10px 10px hsl(178deg 100% 50% / 15%);
  width: 100%;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: 0.3s;
  color: white;
  transition: 200ms all;
  :hover {
    color: white !important;
    border: 1px solid #ff0000 !important;
    background: #ff0000 !important;
  } 
`
export const ButtonGroup = styled.div`
  gap: 7px;
  display: flex;
  flex-direction: column;
`
