import types from './types'

const getInfoBetMiniGame = (payload: any) => ({
  type: types.GET_INFO_BET_MINIGAME,
  payload
})

const getPickValue = (payload: any) => ({
  type: types.GET_PICK_VALUE,
  payload
})

export default { getInfoBetMiniGame, getPickValue }
